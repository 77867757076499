<template>
  <header
    ref="$checkoutHeader"
    class="justify-center flex w-full transition-transform delay-75 duration-500 bg-white sticky top-0 left-0 z-20 translate-y-0"
  >
    <nav id="checkout-header" class="w-full relative transition-colors">
      <div
        class="px-4 md:px-6 3xl:px-12 py-4 md:py-[1.375rem] max-w-screen-3xl mx-auto"
      >
        <ul class="flex justify-between items-center">
          <li
            class="md:inline-block"
            :class="{
              hidden: isStepperVisible,
            }"
          >
            <LogoSvg class="fill-black !h-10" />
          </li>
          <Transition name="fade" mode="out-in">
            <li v-if="isStepperVisible">
              <CheckoutStepper
                v-model:currentStepIdx="checkoutStep"
                :steps="translatedCheckoutSteps"
                :disabled="true"
              />
            </li>
          </Transition>
          <li class="hidden md:inline-block w-[7.375rem]"></li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { toRefs } from "vue";
import { checkoutSteps } from "@/constants/checkoutSteps";
import LogoSvg from "@/components/atoms/LogoSvg";
import CheckoutStepper from "@/components/molecules/CheckoutStepper";
import { useCheckoutStore } from "@/stores";

const { isStepperVisible, checkoutStep } = toRefs(useCheckoutStore());
const { t } = useI18n();

const translatedCheckoutSteps = computed(() =>
  checkoutSteps.map((step) => t(step)),
);

const $checkoutHeader = ref<HTMLElement>();

let prevScrollPos: undefined | number;

const onScroll = () => {
  if (!$checkoutHeader.value) return;

  const currentScrollPos = window.scrollY;
  if (prevScrollPos && prevScrollPos > currentScrollPos) {
    $checkoutHeader.value.style.transform = "translateY(0)";
  } else {
    if (
      currentScrollPos >
      parseInt(getComputedStyle($checkoutHeader.value).height.replace("px", ""))
    ) {
      $checkoutHeader.value.style.transform = `translateY(-100%)`;
    }
  }
  prevScrollPos = currentScrollPos;
};

useEventListener("scroll", onScroll, {
  passive: true,
});
</script>
